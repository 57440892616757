import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "58",
  height: "57",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M28.735.671c13.815-.005 24.859 11.22 28.364 24.67 2.972 11.403-4.642 22.12-14.52 28.455-8.066 5.174-17.885 2.786-25.831-2.575C7.431 44.936-2.171 36.343.433 25.361 3.637 11.85 14.932.677 28.735.672Z",
      fill: "#FFFADF"
    }, null, -1),
    _createElementVNode("path", {
      d: "m20.364 29.944 5.09 5.09 12.728-12.726",
      stroke: "#0D0D0D",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }